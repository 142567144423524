import { Box, Typography } from '@mui/material';
import React from 'react';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import axios from 'axios';
import { RecaptchaComponent } from '../Recaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import isEmail from 'validator/lib/isEmail';
import Link from 'next/link';



const CustomRadio = ({ title, data, value, onChange }) => {
  return (
    <FormControl component="fieldset" sx={{ mt: '35px' }}>
      <FormLabel component="legend">{title}</FormLabel>
      <RadioGroup
        aria-label="När ska ni sälja bostaden?"
        defaultValue="now"
        name="radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        {data.map((item, i) => (
          <FormControlLabel
            key={i}
            value={item}
            control={<Radio />}
            label={item}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const ValuationForm = () => {
  const [namn, setNamn] = React.useState('');
  const [mejladress, setMejladress] = React.useState('');
  const [telefon, setTelefon] = React.useState('');
  const [adress, setAdress] = React.useState('');
  const [postnummer, setPostnummer] = React.useState('');
  const [ort, setOrt] = React.useState('');
  const [kommun, setKommun] = React.useState('');
  const [boarea, setBoarea] = React.useState('');
  const [biarea, setBiarea] = React.useState('');
  const [antalRum, setAntalRum] = React.useState('');

  const [foreningensName, setForeningensName] = React.useState('');
  const [antalTrappor, setAntalTrappor] = React.useState('');
  const [manadsavgift, setManadsavgift] = React.useState('');
  const [lagenhetsnummer, setLagenhetsnummer] = React.useState('');
  const [fastighetsbeteckning, setFastighetsbeteckning] = React.useState('');
  const [ovrigkommentar, setOvrigkommentar] = React.useState('');

  const [formType, setFormType] = React.useState('1');
  const [whenSell, setWhenSell] = React.useState('Nu');

  const [economi, setEconomi] = React.useState('Bra');
  const [skick, setSkick] = React.useState('Normalt');
  const [dagsljus, setDagsljus] = React.useState('Normal');
  const [uteplats, setUteplats] = React.useState('Ingen');
  const [oppen, setOppen] = React.useState('Nej');
  const [hiss, setHiss] = React.useState('Nej');
  const [sjoutsikt, setSjoutsikt] = React.useState('Nej');
  const [trafikstord, setTrafikstord] = React.useState('Nej');
  const [vindsvaning, setVindsvaning] = React.useState('Nej');
  const [contactMe, setContactMe] = React.useState(true);

  const handleFormTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormType((event.target as HTMLInputElement).value);
  };
  const handleWhenSellChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWhenSell((event.target as HTMLInputElement).value);
  };

  const [message, setMessage] = React.useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = React.useCallback(async () => {
    if (!isEmail(mejladress)) {
      setMessage('Ange en korrekt e-postadress.');
      return;
    }
    let msg = '';
    msg += namn !== '' ? 'Namn?\n' + namn + '\n' : '';
    msg += mejladress !== '' ? 'Mejladress?\n' + mejladress + '\n' : '';
    msg += telefon !== '' ? 'Telefon?\n' + telefon + '\n' : '';
    msg += adress !== '' ? 'Adress?\n' + adress + '\n' : '';
    msg += postnummer !== '' ? 'Postnummer?\n' + postnummer + '\n' : '';
    msg += ort !== '' ? 'Ort?\n' + ort + '\n' : '';
    msg += kommun !== '' ? 'Kommun?\n' + kommun + '\n' : '';
    msg += boarea !== '' ? 'Boarea?\n' + boarea + '\n' : '';
    msg += biarea !== '' ? 'Biarea?\n' + biarea + '\n' : '';
    msg += antalRum !== '' ? 'Antal rum?\n' + antalRum + '\n' : '';
    msg +=
      foreningensName !== ''
        ? 'Föreningens namn?\n' + foreningensName + '\n'
        : '';
    msg += antalTrappor !== '' ? 'Antal Trappor?\n' + antalTrappor + '\n' : '';
    msg += manadsavgift !== '' ? 'Månadsavgift?\n' + manadsavgift + '\n' : '';
    msg +=
      lagenhetsnummer !== ''
        ? 'Lägenhetsnummer?\n' + lagenhetsnummer + '\n'
        : '';
    msg +=
      fastighetsbeteckning !== ''
        ? 'Fastighetsbeteckning?\n' + fastighetsbeteckning + '\n'
        : '';
    msg +=
      ovrigkommentar !== '' ? 'Övrig kommentar?\n' + ovrigkommentar + '\n' : '';
    msg += whenSell !== '' ? 'När ska ni sälja bostaden?\n' + whenSell + '\n' : '';
    msg += economi !== '' ? 'Föreningens ekonomi?\n' + economi + '\n' : '';
    msg += skick !== '' ? 'Bostadens skick?\n' + skick + '\n' : '';
    msg += dagsljus !== '' ? 'Dagsljus?\n' + dagsljus + '\n' : '';
    msg += uteplats !== '' ? 'Uteplats?\n' + uteplats + '\n' : '';
    msg += oppen !== '' ? 'Öppen spis/kakelugn?\n' + oppen + '\n' : '';
    msg += hiss !== '' ? 'Hiss?\n' + hiss + '\n' : '';
    msg += sjoutsikt !== '' ? 'Sjöutsikt?\n' + sjoutsikt + '\n' : '';
    msg += trafikstord !== '' ? 'Trafikstörd?\n' + trafikstord + '\n' : '';
    msg += vindsvaning !== '' ? 'Vindsvåning?\n' + vindsvaning + '\n' : '';
    msg += "Kontakta mig för fri rådgivning: " + (contactMe ? "Ja\n" : "Nej\n");
    try {
      const token = await executeRecaptcha('Contact');
      const recaptchaData = await axios.post(
        `${process.env.NEXT_PUBLIC_API_ENDPOINT}v2/valuation`,
        {
          captcha: token,
          message: msg,
          name: namn,
          email: mejladress,
          phone: telefon,
        }
      );
      if (recaptchaData && recaptchaData.data.success === true) {
        setMessage('Meddelandet har skickats.');
        window.dataLayer.push({
          event: "submitted_valuation_form"
        })
      }
    } catch (error) {
      setMessage('Okänt fel. Vänligen kontakta kundtjänst.');
    }
  }, [
    adress,
    antalRum,
    antalTrappor,
    biarea,
    boarea,
    dagsljus,
    economi,
    executeRecaptcha,
    fastighetsbeteckning,
    foreningensName,
    hiss,
    kommun,
    lagenhetsnummer,
    manadsavgift,
    mejladress,
    namn,
    oppen,
    ort,
    ovrigkommentar,
    postnummer,
    sjoutsikt,
    skick,
    telefon,
    trafikstord,
    uteplats,
    vindsvaning,
    whenSell,
  ]);
  return (
    <Box py={3}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="formtype"
          name="controlled-radio-buttons-group"
          value={formType}
          onChange={handleFormTypeChange}
        >
          <FormControlLabel
            value={'1'}
            control={<Radio />}
            label="Värdera bostadsrätt"
          />
          <FormControlLabel
            value={'2'}
            control={<Radio />}
            label="Värdera villa/fritidshus"
          />
        </RadioGroup>
      </FormControl>
      <Box
        px={3}
        sx={{
          display: 'flex',
          // width: '60%',
          justifyContent: 'center',
          flexDirection: 'column',
          // marginLeft: '10%',
        }}
      >
        <TextField
          id="Namn"
          label="Namn"
          variant="outlined"
          sx={{ mt: '15px' }}
          value={namn}
          onChange={(e) => setNamn(e.target.value)}
        />
        <TextField
          id="Mejladress"
          label="Mejladress"
          variant="outlined"
          sx={{ mt: '15px' }}
          value={mejladress}
          onChange={(e) => setMejladress(e.target.value)}
        />
        <TextField
          id="Telefon"
          label="Telefon"
          variant="outlined"
          sx={{ mt: '15px' }}
          value={telefon}
          onChange={(e) => setTelefon(e.target.value)}
        />
        <TextField
          id="Adress"
          label="Adress"
          variant="outlined"
          sx={{ mt: '15px' }}
          value={adress}
          onChange={(e) => setAdress(e.target.value)}
        />
        <TextField
          id="Postnummer"
          label="Postnummer"
          variant="outlined"
          sx={{ mt: '15px' }}
          value={postnummer}
          onChange={(e) => setPostnummer(e.target.value)}
        />
        <TextField
          id="Ort"
          label="Ort"
          variant="outlined"
          sx={{ mt: '15px' }}
          value={ort}
          onChange={(e) => setOrt(e.target.value)}
        />
        <TextField
          id="Kommun"
          label="Kommun"
          variant="outlined"
          sx={{ mt: '15px' }}
          value={kommun}
          onChange={(e) => setKommun(e.target.value)}
        />
        <TextField
          id="Boarea"
          label="Boarea"
          variant="outlined"
          sx={{ mt: '15px' }}
          value={boarea}
          onChange={(e) => setBoarea(e.target.value)}
        />
        <TextField
          id="Biarea"
          label="Biarea"
          variant="outlined"
          sx={{ mt: '15px' }}
          value={biarea}
          onChange={(e) => setBiarea(e.target.value)}
        />
        <TextField
          id="Antal Rum"
          label="Antal Rum"
          variant="outlined"
          sx={{ mt: '15px' }}
          value={antalRum}
          onChange={(e) => setAntalRum(e.target.value)}
        />
        {formType === '1' ? (
          <>
            <TextField
              id="Föreningens namn"
              label="Föreningens namn"
              variant="outlined"
              sx={{ mt: '15px' }}
              value={foreningensName}
              onChange={(e) => setForeningensName(e.target.value)}
            />
            <TextField
              id="Antal trappor"
              label="Antal trappor"
              variant="outlined"
              sx={{ mt: '15px' }}
              value={antalTrappor}
              onChange={(e) => setAntalTrappor(e.target.value)}
            />
            <TextField
              id="Månadsavgift"
              label="Månadsavgift"
              variant="outlined"
              sx={{ mt: '15px' }}
              value={manadsavgift}
              onChange={(e) => setManadsavgift(e.target.value)}
            />
            <TextField
              id="Lägenhetsnummer"
              label="Lägenhetsnummer"
              variant="outlined"
              sx={{ mt: '15px' }}
              value={lagenhetsnummer}
              onChange={(e) => setLagenhetsnummer(e.target.value)}
            />

            <CustomRadio
              title="Föreningens ekonomi?"
              value={economi}
              onChange={(e) => setEconomi(e.target.value)}
              data={['Mindre bra', 'Bra', 'Mycket bra']}
            />

            <CustomRadio
              title="Bostadens skick?"
              value={skick}
              onChange={(e) => setSkick(e.target.value)}
              data={['Dåligt', 'Normalt', 'Gott']}
            />

            <CustomRadio
              title="Dagsljus?"
              value={dagsljus}
              onChange={(e) => setDagsljus(e.target.value)}
              data={['Mörk', 'Normal', 'Ljus']}
            />

            <CustomRadio
              title="Uteplats?"
              value={uteplats}
              onChange={(e) => setUteplats(e.target.value)}
              data={['Ingen', 'Balkong', 'Altan bra']}
            />

            <CustomRadio
              title="Öppen spis/kakelugn?"
              value={oppen}
              onChange={(e) => setOppen(e.target.value)}
              data={['Nej', 'Ja']}
            />

            <CustomRadio
              title="Hiss?"
              value={hiss}
              onChange={(e) => setHiss(e.target.value)}
              data={['Nej', 'Ja']}
            />

            <CustomRadio
              title="Sjöutsikt?"
              value={sjoutsikt}
              onChange={(e) => setSjoutsikt(e.target.value)}
              data={['Nej', 'Ja']}
            />

            <CustomRadio
              title="Trafikstörd?"
              value={trafikstord}
              onChange={(e) => setTrafikstord(e.target.value)}
              data={['Nej', 'Ja']}
            />

            <CustomRadio
              title="Vindsvåning?"
              value={vindsvaning}
              onChange={(e) => setVindsvaning(e.target.value)}
              data={['Nej', 'Ja']}
            />
          </>
        ) : (
          <TextField
            id="Fastighetsbeteckning"
            label="Fastighetsbeteckning"
            variant="outlined"
            sx={{ mt: '15px' }}
            value={fastighetsbeteckning}
            onChange={(e) => setFastighetsbeteckning(e.target.value)}
          />
        )}
        <TextField
          id="Övrig kommentar"
          label="Övrig kommentar"
          variant="outlined"
          sx={{ mt: '35px' }}
          value={ovrigkommentar}
          onChange={(e) => setOvrigkommentar(e.target.value)}
          rows={4}
          multiline
        />
        <CustomRadio
          title="När ska ni sälja bostaden?"
          value={whenSell}
          onChange={handleWhenSellChange}
          data={['Nu', 'Snart', 'Inom 6 månader', 'Längre fram']}
        />
        <FormControlLabel
          value="end"
          control={<Checkbox defaultChecked />}
          label="Kontakta mig för fri rådgivning"
          labelPlacement="end"
          onChange={() => setContactMe(!contactMe)}
          checked={contactMe}
        />
        {message !== '' && (
          <>
            <Typography
              sx={{
                color:
                  message === 'Meddelandet har skickats.' ? 'green' : 'red',
              }}
            >
              {message}
            </Typography>
          </>
        )}
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          sx={{ mt: '15px' }}
          type="submit"
          onClick={handleSubmit}
        >
          Skicka
        </Button>

        <Typography sx={{ marginTop: '20px' }}>
          Ta del av vår <Link href="/integritetspolicy">integritetspolicy</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default ValuationForm;
